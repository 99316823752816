import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useGlobalState } from './Components/GlobalContext';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { authFB } from './Components/Auth/Firebase/FirbaseConfig';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { get_reponse, initialState, loading, refreshToken, setAlerts } from './AppUtils';

import Search from './Components/MicromarketAnalysis/Search';
import ValSearch from './Components/Valuation/ValSearch';
import StSearch from './Components/SaleTransactions/StSearch';
import IIPsearch from './Components/InvestInRealEstate/IipSearch';


const AnalyticsRepo = lazy(() => import('./Components/Analytics/AnalyticsRepo'));
const BarChart = lazy(() => import('./Components/Analytics/BarChart'));
const TotalSold = lazy(() => import('./Components/Analytics/TotalSold'));
const BHKAreaCurPri = lazy(() => import('./Components/Analytics/BHKAreaCurPri'));
const BarChartSearch = lazy(() => import('./Components/Analytics/BarChartSearch'));
const BHKCurPriSearch = lazy(() => import('./Components/Analytics/BHKCurPriSearch'));
const TotalsolSerach = lazy(() => import('./Components/Analytics/TotalsolSerach'));
const AnaSaleSearch = lazy(() => import('./Components/Analytics/AnaSaleSearch'));
const AnaSaleTra = lazy(() => import('./Components/Analytics/AnaSaleTra'));

const AnaRepoSearch = lazy(() => import('./Components/Analytics/AnaRepoSearch'));
const Layout = lazy(() => import('./Components/Layout'));
const Error500 = lazy(() => import('./Components/Error500'));
const HomePage = lazy(() => import('./Components/HomePage/HomePage'));
const AuthLayout = lazy(() => import('./Components/Auth/AuthLayout'));
const ForgotPassword = lazy(() => import('./Components/Auth/Password/Forgot'));
// const Search = lazy(() => import('./Components/PropertyDataAnalytics/Search'));
const ProjectList = lazy(() => import('./Components/MicromarketAnalysis/ProjectList'));
const MMADashboard = lazy(() => import('./Components/MicromarketAnalysis/Dashboard/MicroMarket'));
const MMASaleTransactions = lazy(() => import('./Components/MicromarketAnalysis/MMASaleTransactions'));
// const ValSearch = lazy(() => import('./Components/Valuation/ValSearch'));
const ValDashboard = lazy(() => import('./Components/Valuation/ValDashboard'));
const ComparableProjects = lazy(() => import('./Components/Valuation/ComparableProjects'));
const NearByAmenities = lazy(() => import('./Components/Valuation/NearByAmenities'));
const ReraInfo = lazy(() => import('./Components/Valuation/ReraInfo'));
const AboutUs = lazy(() => import('./Components/AboutUs'));
const ContactUs = lazy(() => import("./Components/Contact/ContactUs"));
const OurProducts = lazy(() => import("./Components/OurProducts"));
const ProjectDetails = lazy(() => import('./Components/MicromarketAnalysis/ProjectDetails'));
const ResetPassword = lazy(() => import('./Components/Auth/Password/Reset'));
const Error404 = lazy(() => import('./Components/Error404'));
const ComingSoon = lazy(() => import('./Components/ComingSoon'));
const PrivacyPolicy = lazy(() => import('./Components/PrivacyPolicy'));
const TermsConditions = lazy(() => import('./Components/TermsConditions'));
const EditProfile = lazy(() => import('./Components/Profile/EditProfile'));
const Profile = lazy(() => import('./Components/Profile/Profile'));
const History = lazy(() => import('./Components/Profile/History'));
const SaleTransaction = lazy(() => import('./Components/SaleTransactions/SaleTransaction'));
// const StSearch = lazy(() => import('./Components/SaleTransactions/StSearch'));
const Solution = lazy(() => import('./Components/Solution'));
const Vision = lazy(() => import('./Components/Vision'));
const CaseStudy = lazy(() => import('./Components/Casestudy/CaseStudy'));
const CaseStudy2 = lazy(() => import('./Components/Casestudy/CaseStudy2'));
const CaseStudy1 = lazy(() => import('./Components/Casestudy/CaseStudy1'));
const CaseStudy3 = lazy(() => import('./Components/Casestudy/CaseStudy3'));
const JustSignIt = lazy(() => import('./Components/Auth/JustSignIt'));
const ValuationReport = lazy(() => import('./Components/Valuation/ValuationReport/ValuationReport'));
const Locationwiseanalysis = lazy(() => import('./Components/InvestInRealEstate/LocationAnalysis'));
const InvestList = lazy(() => import('./Components/InvestInRealEstate/InvestList'));
const InvestInProject = lazy(() => import('./Components/InvestInRealEstate/InvestInProject'));
// const IIPsearch = lazy(() => import('./Components/InvestInRealEstate/IipSearch'));

export const SignOut = (props) => {
    const [gState, setGState] = useGlobalState();
    const [loggedOut, setLoggedOut] = useState(false);

    useEffect(() => {
        document.title = `Sign Out${gState?.appTitle}`;

        loading();
        get_reponse(`/auth/logout/`, { ...refreshToken() }, () => {
            setGState({
                ...initialState,
            });

            window.isAuth = null;
            window.isMmaAllowed = null;
            window.user = null;

            signOut(authFB).then(() => {
                // Sign-out successful.
                setLoggedOut(true);
            }).catch((error) => {
                // An error happened.
                setAlerts('error', 'Sign-Out Error: ' + error);
            });
            loading(false);
        });

        return () => {
            document.title = gState?.appName;
        }
    }, [gState?.appName, gState?.appTitle, setGState]);


    return (
        loggedOut && <Navigate to={`/auth/signin/`} />
    )
}


function App() {
    const subdomain = window?.location?.hostname?.split('.')[0];
    const [gState, setGState] = useGlobalState();

    useEffect(() => {
        try {
            document && document?.getElementById('id_csrf_div')?.remove();
        } catch (error) {
            console.log(error);
        }

        window?.addEventListener("resize", (ev = null) => {
            if (gState?.isMobile !== (window.innerWidth <= 768)) {
                setGState({
                    isMobile: window.innerWidth <= 768
                });
            }
        })

    }, [gState?.isMobile, setGState]);



    useEffect(() => {
        (
            (window?.isAuth !== null) &&
            (window?.user !== null) &&
            (window?.isMmaAllowed !== null)
        ) && setGState({
            isAuth: window?.isAuth,
            user: window?.user,
            isMmaAllowed: window?.isMmaAllowed,
        });

        window.isAuth = null;
        window.user = null;
        window.isMmaAllowed = null;

    }, [setGState]);



    // useEffect(() => {
    //     gState.isAuth === null && get_auth_and_usr(setGState);
    // }, [gState.isAuth, setGState]);



    return (
        <>
            <Router>
                <Suspense fallback={
                    <div className="page-loading active">
                        <div className="page-loading-inner">
                            {/* <div className="page-spinner"></div> */}
                            <video autoPlay loop muted playsInline style={{ maxHeight: '14rem' }}>
                                <source src={`/static/Images/loading/SigmaValue_Loading_${(gState?.theme ? gState?.theme : "light")}.mp4`} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <span>Loading...</span>
                        </div>
                    </div>
                }>
                    <Routes>
                        <Route path='' element={<Layout>{subdomain === 'analytics' ? <Search /> : <HomePage />}</Layout>} />
                        <Route path='/about/' element={<Layout><AboutUs /></Layout>} />
                        <Route path='/contact/' element={<Layout><ContactUs /></Layout>} />
                        <Route path='/products/' element={<Layout><OurProducts /></Layout>} />
                        <Route path='/solution/' element={<Layout><Solution /></Layout>} />
                        <Route path='/vision/' element={<Layout><Vision /></Layout>} />


                        <Route path='/privacypolicy' element={<Layout><PrivacyPolicy /></Layout>} />
                        <Route path='/privacypolicy/' element={<Layout><PrivacyPolicy /></Layout>} />
                        <Route path='/termsandconditions' element={<Layout><TermsConditions /></Layout>} />
                        <Route path='/termsandconditions/' element={<Layout><TermsConditions /></Layout>} />


                        {/* Auth Urls */}
                        <Route path='/auth/signin/' element={<AuthLayout><JustSignIt /></AuthLayout>} />
                        <Route path='/auth/password/forgot/' element={<AuthLayout><ForgotPassword /></AuthLayout>} />
                        <Route path='/auth/password/reset/:uid/:token/' element={<AuthLayout><ResetPassword /></AuthLayout>} />
                        <Route path='/auth/password/change/' element={<AuthLayout><EditProfile /></AuthLayout>} />
                        <Route path='/auth/justsignit/' element={<AuthLayout><JustSignIt /></AuthLayout>} />
                        <Route path='/auth/signout/' element={<SignOut />} />


                        {/* Profile url */}
                        <Route path='/profile/' element={<Layout><Profile /></Layout>} />
                        <Route path='/profile/edit/' element={<Layout><EditProfile /></Layout>} />
                        <Route path='/profile/history/' element={<Layout><History /></Layout>} />


                        {/* Valuation Urls */}
                        <Route path='/valuation/' element={<Layout><ValSearch /></Layout>} />
                        <Route path='/valuation/dashboard/' element={<Layout><ValDashboard /></Layout>} />
                        <Route path='/valuation/comparables/' element={<Layout><ComparableProjects /></Layout>} />
                        <Route path='/valuation/nearbyamenities/' element={<Layout><NearByAmenities /></Layout>} />
                        <Route path='/valuation/projectinfo/' element={<Layout><ReraInfo /></Layout>} />
                        <Route path='/valuation/valuationreport/' element={<Layout><ValuationReport /></Layout>} />

                        {/* MMA Urls */}
                        <Route path='/property-data-analytics/' element={<Layout><Search /></Layout>} />
                        <Route path='/property-data-analytics/list/' element={<Layout><ProjectList /></Layout>} />
                        <Route path='/property-data-analytics/dashboard/' element={<Layout><MMADashboard /></Layout>} />
                        <Route path='/property-data-analytics/saletransactions/' element={<Layout><MMASaleTransactions /></Layout>} />
                        <Route path='/property-data-analytics/projectdetails/' element={<Layout><ProjectDetails /></Layout>} />


                        {/* Sale Transaction Urls */}
                        <Route path='/st/' element={<Layout><StSearch /></Layout>} />
                        <Route path='/st/list/' element={<Layout><SaleTransaction /></Layout>} />


                        {/* Invest In Real Estate Urls */}
                        <Route path='/invest/' element={<Layout><IIPsearch /></Layout>} />
                        <Route path='/invest/list/' element={<Layout><InvestList /></Layout>} />
                        <Route path='/invest/project/' element={<Layout><InvestInProject /></Layout>} />
                        <Route path='/invest/locationwiseanalysis/' element={<Layout><Locationwiseanalysis /></Layout>} />


                        <Route path='/analytics/anareposearch/' element={<Layout><AnaRepoSearch /></Layout>} />
                        <Route path='/analytics/barchartsearch/' element={<Layout><BarChartSearch /></Layout>} />
                        <Route path='/analytics/bhkcurprisearch/' element={<Layout><BHKCurPriSearch /></Layout>} />
                        <Route path='/analytics/totalsolsearch/' element={<Layout><TotalsolSerach /></Layout>} />
                        <Route path='/analytics/analyticsReport/' element={<Layout><AnalyticsRepo /></Layout>} />
                        <Route path='/analytics/barchart/' element={<Layout><BarChart /></Layout>} />
                        <Route path='/analytics/totalsold/' element={<Layout><TotalSold /></Layout>} />
                        <Route path='/analytics/bhkareacurpri/' element={<Layout><BHKAreaCurPri /></Layout>} />
                        <Route path='/analytics/anasaletra/' element={<Layout><AnaSaleTra /></Layout>} />
                        <Route path='/analytics/anasaleserach/' element={<Layout><AnaSaleSearch /></Layout>} />



                        <Route path='/error-404/' element={<Layout><Error404 /></Layout>} />
                        <Route path='/error-500/' element={<Layout><Error500 /></Layout>} />
                        <Route path='/coming-soon/' element={<Layout><ComingSoon /></Layout>} />
                        <Route path='/maintaince/' element={<Layout><Error404 /></Layout>} />

                        <Route path='/casestudy/' element={<Layout><CaseStudy /></Layout>} />
                        <Route path='/casestudy1/' element={<Layout><CaseStudy1 /></Layout>} />
                        <Route path='/casestudy2/' element={<Layout><CaseStudy2 /></Layout>} />
                        <Route path='/casestudy3/' element={<Layout><CaseStudy3 /></Layout>} />

                        <Route path="/auth/oauth/callback/" element={<Navigate to="/" />} />
                        <Route path="*" element={<Layout><Error404 /></Layout>} />

                    </Routes>
                </Suspense>

                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
            </Router>
        </>
    );
}

export default App;
