import React, { useState, useEffect, useCallback } from "react";
import { updateUrlParamsOnly, getDictToParamsUrl, getUrlParamsDict, get_data, loading, refreshToken, setAlerts, removeUrlParams } from '../../AppUtils';
import {
    getLatLng,
    geocodeByAddress,
} from 'react-google-places-autocomplete';
import { Navigate, useNavigate } from "react-router-dom";
import ValHowItWorks from "../HomePage/ValHowItWorks";
import ValDisclaimer from "./ValDisclaimer";
import GPlaceAutoComplete from "../Utils/GPlaceAutoComplete";
import { ReaSelect } from "../Utils/ReaSelect";
import { useGlobalState } from "../GlobalContext";



const Search = (props) => {
    const [gState, setGState] = useGlobalState();
    const params = getUrlParamsDict();
    const srhBdy = gState?.valuationSearch ? gState?.valuationSearch : params;

    const [query, setQuery] = useState();
    const [city, setCity] = useState(srhBdy?.City ? srhBdy?.City : null);
    const [gVal, setGVal] = useState(srhBdy?.gval_lbl || params?.stSelection ? {
        label: srhBdy?.gval_lbl ? srhBdy?.gval_lbl : params?.stSelection,
        value: {
            structured_formatting: {
                main_text: srhBdy?.gval_val_mt ? srhBdy?.gval_val_mt : params?.stSelection,
                secondary_text: srhBdy?.gval_val_st ? srhBdy?.gval_val_st : params?.stSelection,
            }
        }
    } : null);
    const [propertyName, setPropertyName] = useState(srhBdy?.build ? srhBdy?.build : (params?.stSelection ? params?.stSelection : null));
    const [coord, setCoord] = useState(srhBdy?.lat && srhBdy?.lng ? {
        lat: srhBdy?.lat,
        lng: srhBdy?.lng,
    } : {
        lat: 18.5653103,
        lng: 73.83751099999999,
    });
    const [bhk, setBhk] = useState(srhBdy?.bhk ? srhBdy?.bhk : 2);
    const [area, setArea] = useState(srhBdy?.area ? srhBdy?.area : null);
    const [terraceArea, setterraceArea] = useState(srhBdy?.terraceArea ? srhBdy?.terraceArea : null);
    const [age, setAge] = useState(srhBdy?.age ? srhBdy?.age : null);
    const [randomfloor, setRandomFloor] = useState(srhBdy?.floors ? srhBdy?.floors : (params?.total_floors ? params?.total_floors : null));
    const [prefFloor, setPrefFloor] = useState(srhBdy?.pref_floor ? srhBdy?.pref_floor : null);
    const [neighAmme, setNeighAmme] = useState(srhBdy?.neighbourhoodAmenities ? srhBdy?.neighbourhoodAmenities : null);
    const [areaType, setAreaType] = useState(srhBdy?.areaType ? srhBdy?.areaType : "Super Builtup/ Saleable");
    const [center, setCenter] = useState({ lat: 18.52653, lng: 73.855532 });
    const [nextTo, setNextTo] = useState(null);

    const navigate = useNavigate();

    const opts = [
        { "label": "Pune", "value": "Pune" },
        { "label": "Mumbai", "value": "Mumbai" },
        // { "label": "Thane", "value": "Thane" },
    ]

    gState?.user?.fields?.is_superuser && !opts.includes({ "label": "Ahmedabad", "value": "Ahmedabad" }) && opts.push({ "label": "Ahmedabad", "value": "Ahmedabad" });
    gState?.user?.fields?.is_superuser && !opts.includes({ "label": "Bangalore", "value": "Bangalore" }) && opts.push({ "label": "Bangalore", "value": "Bangalore" });
    gState?.user?.fields?.is_superuser && !opts.includes({ "label": "Ghaziabad", "value": "Ghaziabad" }) && opts.push({ "label": "Ghaziabad", "value": "Ghaziabad" });


    const getBuildFloors = useCallback((hmSearch = null) => {
        loading();
        if ((gVal && propertyName && city) || hmSearch) {
            const body = ("hmSearch" in props && gState?.hmSearch && gState?.hmSearch.searchFor === "Valuation" && hmSearch) ? {
                City: hmSearch.city,
                build: hmSearch.value.value.structured_formatting.main_text,
                loc: hmSearch.value.value.structured_formatting.secondary_text,
                lng: hmSearch.coord.lng,
                lat: hmSearch.coord.lat,
            } : {
                City: city,
                build: propertyName,
                loc: gVal?.value.structured_formatting.secondary_text,
                lng: coord.lng,
                lat: coord.lat,
            };

            const bodyFile = {
                ...refreshToken(),
                body: JSON.stringify(body),
            };

            fetch(`${process.env.REACT_APP_BACKEND_URL}totalFloor`, bodyFile)
                .then((response) => response.json())
                .then((response) => {
                    setGState({ hmSearch: null });
                    setQuery({ ...body, totalFloor: response.totalFloor });
                }).catch(err => {
                    setAlerts("error", err);
                });
        }
        loading(false);
    }, [city, coord.lat, coord.lng, gState?.hmSearch, gVal, propertyName, props, setGState]);

    randomfloor === null && getBuildFloors();


    const onSubmit = (ev = null) => {
        ev?.preventDefault();

        if("stSelection" in params){
            if ("valuationPaid" in gState) {
                if (!gState?.valuationPaid) {
                    return
                }
            } else {
                return
            }
        }
        
        if (city && gVal && randomfloor && prefFloor && coord && area) {
            loading();
            const body = {
                ...params,
                City: city,
                build: propertyName,
                loc: gVal?.value.structured_formatting.secondary_text,
                area: area,
                floors: randomfloor ? randomfloor : query.totalFloor,
                pref_floor: prefFloor,
                bhk: bhk ? bhk : 0,
                lng: coord.lng,
                lat: coord.lat,
                areaType: areaType,
                age: age,
                terraceArea: terraceArea,
                neighbourhoodAmenities: neighAmme,
                gval_lbl: gVal?.label,
                gval_val_mt: gVal?.value.structured_formatting.main_text,
                gval_val_st: gVal?.value.structured_formatting.secondary_text,
            };

            const bodyFile = {
                ...refreshToken(),
                body: JSON.stringify(body),
            };

            get_data(
                process.env.REACT_APP_BACKEND_URL + "result",
                bodyFile
            ).then((parseRes) => {
                if (parseRes?.valuation !== "na") {
                    setGState({
                        valuationSearch: body,
                        valuationResult: parseRes,
                    });

                    let url = getDictToParamsUrl("/valuation/dashboard/", body);
                    updateUrlParamsOnly(body);
                    setNextTo(url);
                } else {
                    setAlerts("error", "Requested data not recived...");
                }

            }).catch((err) => {
                setAlerts("error", "Valuation: Something went wrong...");
                console.log("Valuation Error:");
                console.log(err);

            }).finally(() => {
                loading(false);
            });
        } else {
            setAlerts("error", "All fields are required...");
        }

    };


    const onReset = (ev = null) => {
        setQuery(null);
        setCity(null);
        setGVal(null);
        setPropertyName(null);
        setCoord({
            lat: 18.5653103,
            lng: 73.83751099999999,
        });
        setBhk(2);
        setArea("");
        setterraceArea(null);
        setAge("");
        setRandomFloor("");
        setPrefFloor("");
        setNeighAmme(null);
        setAreaType("Super Builtup/ Saleable");
        setGState({
            valuationSearch: null,
        });

        removeUrlParams();

    }




    useEffect(() => {
        if (gVal && window?.google) {
            setPropertyName(
                gVal && gVal?.value?.structured_formatting?.main_text
            );

            geocodeByAddress(gVal.label).then((result) => {
                getLatLng(result[0]).then((googleCoord) =>
                    setCoord((prvSt) => ({ ...googleCoord }))
                ).catch((err) => {
                    console.log("Valuation Coord Error: ");
                    console.log(err);
                });
                // getLatLng(getGResult(result)).then((googleCoord) =>
                //     setCoord((prvSt) => ({ ...googleCoord }))
                // );
            });
        }
    }, [gVal]);



    useEffect(() => {
        if (coord) {
            getBuildFloors();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coord]);



    useEffect(() => {
        if (query) {
            setRandomFloor(query.totalFloor);
        }
    }, [query]);



    useEffect(() => {
        if (city) {
            switch (city) {
                case "Pune":
                    setCenter({ lat: 18.52653, lng: 73.855532 });
                    break;

                case "Mumbai":
                    setCenter({ lat: 19.1058712, lng: 72.8989012 });
                    break;

                case "Bangalore":
                    setCenter({ lat: 12.974072, lng: 77.57738 });
                    break;

                case "Thane":
                    setCenter({ lat: 19.218330, lng: 72.978088 });
                    break;

                case "Ahmedabad":
                    setCenter({ lat: 23.0277414, lng: 72.5618733 });
                    break;

                case "Ghaziabad":
                    setCenter({ lat: 28.6689068, lng: 77.4468661 });
                    break;

                default:
                    setCenter({ lat: 18.52653, lng: 73.855532 })
                    break;
            }
        }
    }, [city]);



    useEffect(() => {

        if (gState?.hmSearch && gState?.hmSearch.searchFor === "Valuation") {
            setCity(gState?.hmSearch.city);
            setGVal(gState?.hmSearch.value);
            setCoord(gState?.hmSearch.coord);
            getBuildFloors(gState?.hmSearch);
        }

    }, [gState?.hmSearch, getBuildFloors]);


    
    useEffect(() => {
        gState?.isAuth !== true && (setAlerts("error", "Valuation: Please Sign-In/Up to this service...") || navigate('/'));
    }, [gState?.isAuth, navigate]);



    return (
        nextTo ? <Navigate to={nextTo} /> : <>
            {/* <!-- Page title + Сontact form --> */}
            <ValDisclaimer {...props} />
            <div className='row bg-secondary justify-content-center pb-5' style={{ minHeight: '80vh' }}>
                {/* <!-- Header and footer --> */}
                <div className="col-md-8 my-auto card border border-3" style={{ borderRadius: "0.5rem" }}>
                    <div className="card-header text-center">
                        <h4 className="card-title mb-0 display-6 text-primary">Valuation</h4>
                    </div>
                    <div className="card-body">
                        <div className="card-text">
                            {/* <!-- Valuation form --> */}
                            <form className="row g-4 needs-validation" id='id_valutaion_search_form' method='POST' onSubmit={(ev) => onSubmit(ev)}>
                                <div className="col-sm-6">
                                    <label className='form-label fs-base'>
                                        City
                                    </label>

                                    <ReaSelect
                                        {...props}
                                        isDisabled={false}
                                        value={city && {
                                            "label": city,
                                            "value": city
                                        }}
                                        onChange={(val) => {
                                            onReset();
                                            setCity(val?.value);
                                        }}
                                        options={opts.sort((a, b) => a.label < b.label ? -1 : a.label > b.label ? 1 : 0)}

                                        border={true}
                                        maxHeight={`38px`}
                                        placeholder="Search or Select City..."

                                    />

                                </div>
                                <div className="col-sm-6">
                                    <label className="form-label fs-base">
                                        Property Or Building Name
                                    </label>
                                    <GPlaceAutoComplete
                                        {...props}
                                        center={center}
                                        isDisabled={params?.stSelection ? true : !city}
                                        value={gVal}
                                        onChange={(val) => {
                                            let ct = city;
                                            onReset();
                                            setCity(ct);
                                            setGVal(val);
                                        }}

                                        border={true}
                                        maxHeight={`38px`}
                                        placeholder="Type to search for a property or building name..."

                                    />

                                </div>
                                <div className="col-sm-6">
                                    <label className="form-label fs-base">Total Floors in the Building</label>
                                    <input className="form-control form-control-sm" type="number" placeholder="Total Floors in the Building" id="total_floors" defaultValue={randomfloor} onChange={(ev) => setRandomFloor(ev.target.value)} min={0} required />
                                    <div className="invalid-feedback">Please enter Total Floors!</div>
                                </div>
                                <div className="col-sm-6">
                                    <label className="form-label fs-base">Floor of the unit (Floor on which property lies)</label>
                                    <input className="form-control form-control-sm" type="number" placeholder="Floor on which property lies" id="pref_floor" value={prefFloor} onChange={(ev) => setPrefFloor(ev.target.value)} min={0} required />
                                    <div className="invalid-feedback">Please provide Floor on which property lies!</div>
                                </div>
                                <div className="col-sm-4">
                                    <label className="form-label fs-base">BHK of Unit</label>
                                    <input className="form-control form-control-sm" type="number" placeholder="BHK of Unit" id="bhk" value={bhk} onChange={(ev) => setBhk(ev.target.value)} min={1} />
                                </div>
                                <div className="col-sm-4">
                                    <label className="form-label fs-base">Type of Area *</label>

                                    <ReaSelect
                                        {...props}
                                        isDisabled={false}
                                        value={areaType && {
                                            "label": areaType,
                                            "value": areaType
                                        }}
                                        onChange={(val) => {
                                            setAreaType(val?.value);
                                        }}
                                        options={[
                                            { "label": "Builtup", "value": "Builtup" },
                                            { "label": "Super Builtup/ Saleable", "value": "Super Builtup/ Saleable" },
                                            { "label": "Carpet area (Inclusive of Balcony area)", "value": "Carpet area (Inclusive of Balcony area)" },
                                        ]}

                                        border={true}
                                        maxHeight={`38px`}
                                        placeholder="Search or Select Type of Area..."

                                    />

                                </div>
                                <div className="col-sm-4">
                                    <label className="form-label fs-base">Area of Unit (in sq ft)</label>
                                    <input className="form-control form-control-sm" type="number" placeholder="Area" id="area" value={area} onChange={(ev) => setArea(ev.target.value)} min={1} required />
                                </div>
                                <div className="col-sm-4">
                                    <label className="form-label fs-base">Terrace Area</label>
                                    <input className="form-control form-control-sm" type="number" placeholder="Terrace Area" id="terrace-area" value={terraceArea} onChange={(ev) => setterraceArea(ev.target.value)} disabled={
                                        areaType === "Super Builtup/ Saleable"
                                            ? true
                                            : false
                                    }
                                        required={
                                            areaType === "Super Builtup/ Saleable"
                                                ? false
                                                : true
                                        } />
                                </div>
                                <div className="col-sm-4">
                                    <label className="form-label fs-base">Age of property</label>
                                    <input className="form-control form-control-sm" type="number" placeholder="Age of property" id="age" value={age} onChange={(ev) => setAge(ev.target.value)} min={0} required />
                                </div>
                                <div className="col-sm-4">
                                    <label className="form-label fs-base" >Neighbourhood Amenities & Aesthetics</label>

                                    <ReaSelect
                                        {...props}
                                        isDisabled={false}
                                        value={neighAmme && {
                                            "label": neighAmme,
                                            "value": neighAmme
                                        }}
                                        onChange={(val) => {
                                            setNeighAmme(val?.value);
                                        }}
                                        options={[
                                            { "label": "Excellent", "value": "Excellent" },
                                            { "label": "Good", "value": "Good" },
                                            { "label": "Average", "value": "Average" },
                                            { "label": "Underdeveloped", "value": "Underdeveloped" },
                                        ]}

                                        border={true}
                                        maxHeight={`38px`}
                                        placeholder="Search or Select Neighbourhood..."

                                    />

                                </div>
                                <b className="col-sm-12 mt-5">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">* Builtup Area = carpet area + Wall thickness</li>
                                        <li className="list-group-item">* Super Builtup Area/ Saleable Area - Saleable area includes Carpet area, Walls, Terrace , Common areas apportioned to the unit like Lobby, common amenties etc. In short it is Carpet Area * Loading factor + Terrace area</li>
                                        <li className="list-group-item border-bottom">* Carpet Area - An area that can be covered by carpet or net usable area is called carpet area. The carpet area is the distance between the inner walls.</li>
                                    </ul>
                                </b>
                            </form>
                            <div className="col-sm-12 pt-2 text-center">
                                <button className="btn btn-primary" form='id_valutaion_search_form' type="submit" style={{ borderRadius: "0.5rem" }}>Get Value</button>
                                <button className="btn btn-secondary m-2" form='id_valutaion_search_form' type="reset" style={{ borderRadius: "0.5rem" }} onClick={(ev) => onReset(ev)}>Reset</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ValHowItWorks {...props} />

        </>
    )
}

export default Search